.nft {
    max-width: 300px;
    height: 450px; /* Fixed height for consistent card size */
    color: aliceblue;
    margin: 5rem auto;
    border: 1px solid #ffffff22;
    background-color: #282c34;
    background: linear-gradient(0deg, rgba(40,44,52,1) 0%, rgba(17,0,32,.5) 100%);
    box-shadow: 0 7px 20px 5px #00000088;
    border-radius: 0.7rem;
    overflow: hidden; /* Prevents content from overflowing */
    position: relative; /* Relative positioning for hr */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures hr stays at the bottom */
    transition: .5s ;

    ::before{
        position: fixed;
        content: "";
        box-shadow: 0 0 100px 40px #ffffff08;
        top: -10%;
        left: -100%;
        transform: rotate(-45deg);
        height: 60rem;
        transition: .7s all;
      }
      &:hover{
        border: 1px solid #ffffff44;
        box-shadow: 0 7px 50px 10px #000000aa;
        transform: scale(1.015);
        filter: brightness(1.3);
        ::before{
          filter: brightness(.5);
          top: -100%;
          left: 200%;
        }
      }
  }
  
  .main {
    padding: 1rem;
    flex: 1; /* Allows main content to take up available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .tokenImage {
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .social-icons {
    padding: 0;
    list-style: none;
    margin: 1em;
    
  }
  .social-icons i {
    color: #fff;
    position: absolute;
    top: 0.95em;
    left: 0.96em;
    transition: all 265ms ease-out;
  }
  
.social-icons li {
  display: inline-block;
  margin: 0.15rem;
  position: relative;
  font-size: 5rem;
}

.social-icons i {
  color: #fff;
  position: absolute;
  top: 0.95em;
  left: 0.96em;
  transition: all 265ms ease-out;
}
 
  .social-icons a:before {
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    content: " ";
    width: 45px;
    height: 45px;
    border-radius: 100%;
    display: block;
    background: linear-gradient(45deg, #ff003c, #c648c8);
    transition: all 265ms ease-out;
  }
  
  .social-icons a:hover:before {
    transform: scale(0);
    transition: all 265ms ease-in;
  }
  
  .social-icons a:hover i {
    -ms-transform: scale(2.2);
    -webkit-transform: scale(2.2);
    transform: scale(2.2);

    color: #ff003c;
    background: -webkit-linear-gradient(45deg, #ff003c, #c648c8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 265ms ease-in;
  }

  
  .text-start {
    margin: 10px 0;
  }
  
  .domains p {
    margin: 5px 0;
  }
  
  .cityname {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .city, .pincode {
    color: #ee83e5;
    font-weight: 700;
  }
  
  hr {
    width: 100%;
    position: absolute;
    bottom: 3px; /* Sticks hr at the bottom */
    border: none;
    border-bottom: 1px solid #88888855;
    margin: 0;
  }
  
  .nft:hover {
    border: 1px solid #ffffff44;
    box-shadow: 0 7px 50px 10px #000000aa;
    transform: scale(1.015);
    filter: brightness(1.3);
  }
  
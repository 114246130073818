
.img-circle{
    border-radius: 25%;
    overflow: hidden;
    max-height: 200px;
    width: auto;
}
.Mentors{
    background-color:  #f0f0f0;
    color: rgb(35, 20, 34);

}

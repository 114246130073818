.hero17-header78 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.hero17-row-container1, .hero17-row-container2 {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.thq-animated-group-horizontal, .thq-animated-group-horizontal-reverse {
  display: flex;
  animation: scroll-horizontal 20s linear infinite;
  justify-content: space-between;
}

.thq-animated-group-horizontal-reverse {
  animation-direction: reverse;
}

.thq-img-scale {
  width: 200px; /* Adjust based on your image size */
  height: 200px; /* Adjust based on your image size */
  margin-right: 10px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.thq-img-scale:hover {
  transform: scale(1.05);
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.hero17-column {
  gap: var(--dl-space-space-oneandhalfunits);
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.hero17-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.hero17-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.hero17-row-container1 {
  width: 100%;
}
.hero17-placeholder-image10 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image11 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image12 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image13 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image14 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image15 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image16 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image17 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image18 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image19 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image20 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image21 {
  width: 400px;
  height: 400px;
}
.hero17-row-container2 {
  width: 100%;
}
.hero17-placeholder-image22 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image23 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image24 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image25 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image26 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image27 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image28 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image29 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image30 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image31 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image32 {
  width: 400px;
  height: 400px;
}
.hero17-placeholder-image33 {
  width: 400px;
  height: 400px;
}
.hero17-container2 {
  display: contents;
}
.hero17-text3 {
  display: inline-block;
  text-align: center;
}
.hero17-text4 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 767px) {
  .hero17-content2 {
    width: 100%;
  }
}

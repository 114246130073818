.card-registration .select-input.form-control[readonly]:not([disabled]) {
    font-size: 1rem;
    line-height: 2.15;
    padding-left: .75em;
    padding-right: .75em;
    }
    .card-registration .select-arrow {
    top: 13px;
    }
    .form-check-input[type="radio"] {
        background-color: #343a40;  /* Dark background for unchecked state */
        border-color: #343a40;      /* Dark border */
      }
  
      .form-check-input[type="radio"]:checked {
        background-color: #000;     /* Darker color for checked state */
        border-color: #000;         /* Darker border when checked */
      }
  
      /* To customize the inner circle of checked radio buttons */
      .form-check-input[type="radio"]:checked::before {
        background-color: #fff;     /* Inner circle of checked radio button */
      }
  
      /* Optional: Darken the focus ring */
      .form-check-input[type="radio"]:focus {
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
    }
    .submit{
        background-color: var(--dl-color-theme-primary1);
    }
   
    .form-check {
      display: flex;
      align-items: center; /* Center vertically */
      justify-content: center; /* Center horizontally */
      margin-bottom: 20px; /* Space between checkbox and other elements */
    }
    
    @media (max-width: 576px) {
      .form-check {
        flex-direction: column; /* Stack elements on small screens */
        align-items: flex-start; /* Align items to the start */
      }
    }
    .tc{
      text-decoration: none;
    }
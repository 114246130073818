.contact3-contact20 {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.contact3-max-width {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact3-section-title {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact3-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact3-row {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
}
.contact3-content2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact3-contact-info1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact3-content3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact3-text13 {
  align-self: stretch;
  text-align: center;
}
.contact3-content4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact3-contact-info2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact3-content5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact3-text15 {
  align-self: stretch;
  text-align: center;
}
.contact3-content6 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact3-contact-info3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact3-content7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-direction: column;
}
.contact3-text17 {
  align-self: stretch;
  text-align: center;
}
.contact3-text19 {
  display: inline-block;
  text-align: center;
}
.contact3-text22 {
  display: inline-block;
}
.contact3-text23 {
  display: inline-block;
}
.contact3-text24 {
  display: inline-block;
}
.contact3-text25 {
  display: inline-block;
}
.contact3-text26 {
  display: inline-block;
}
.contact3-text27 {
  display: inline-block;
  text-align: center;
}
.contact3-text28 {
  display: inline-block;
  text-align: center;
}
.contact3-text29 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 767px) {
  .contact3-row {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .contact3-row {
    align-items: stretch;
  }
}
